import { createRoot } from 'react-dom/client';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.scss';
import Slider from './Components/Common/Slider';
import Style from './Components/Common/Style';

document.addEventListener('DOMContentLoaded', () => {
	const sliderEls = document.querySelectorAll('.wp-block-csb-content-slider-block');
	Object.values(sliderEls).map(slider => {
		const attributes = JSON.parse(slider.dataset.attributes);
		const { cId } = attributes;

		createRoot(slider).render(<>
			<Style attributes={attributes} clientId={cId} />
			<Slider attributes={attributes} />
		</>);

		slider?.removeAttribute('data-attributes');
	});
});